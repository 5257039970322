(function () {
    angular.module('informaApp')
        .service('AuthorizationErrorsCodes', AuthorizationErrorsCodes);

    function AuthorizationErrorsCodes() {
        return {
            emailIsNotVerified: 0,
            forbidden: 1
        };
    }
})();